import React from 'react';
import logo from './logo.svg';
import './App.css';
import { runInThisContext } from 'vm';


class FormComponent extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      contactname: '',
      contactphone: '',
      formClass: 'expanded',
      textClass: 'b-toggle'
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event){
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    let url = 'http://dev.joint-studio.ru/php/valia.php';
    let data = '&contactname=' + this.state.contactname + '&contactphone=' + this.state.contactphone;
    
    this.setState({
      'formClass' : 'b-toggle',
      'textClass' : 'expanded',
    });
    
    fetch(url, {
      method: 'POST',
      body: data,
      headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(res => res.json())
    .then(response => console.log('Успех:', JSON.stringify(response)))
    .catch(error => console.error('Ошибка:', error));
  }

  render(){
    return(
    <React.Fragment>
    <p className={this.state.textClass}>Ваши данные отправлены. Мы свяжемся с вами в ближайшее время.</p>
    <form className={this.state.formClass} onSubmit={this.handleSubmit}>
      <p className="beforeform">Мы позвоним вам в течение 10 минут</p>
      <div className="form-group">
        <p className="l-form-label">Ваше полное имя *</p>
        <input className="l-form-input" name="contactname" value={this.state.value} onChange={this.handleChange} required/>
      </div>
      <div className="form-group">
        <p className="l-form-label">Ваш номер телефона *</p>
        <input  className="l-form-input" name="contactphone" value={this.state.value} onChange={this.handleChange} required/>
      </div>
      <button className="l-form-button" type="submit"><i class="far fa-hand-point-right"></i> Отправить</button>
    </form>
    </React.Fragment>
    );

  }
}

class HandlerTextChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countStirg: '0',
      countChar: '0',
      currentString: '',
      myString: ["просто сайт ", "landing page ", "сайт-визитку ", "интернет магазин "]
    };
  }
  
  componentDidMount() {
    setInterval(() => {

      this.state.myString.forEach((mystr) => {

        mystr.split('').forEach((mychar) => {
          loopConter++;
          setTimeout( () => {
            const cString = this.state.currentString;
            this.setState({
              currentString: cString + mychar,
            });
          }, 170 * loopConter);
  
        });

        mystr.split('').forEach((mychar) => {
          loopConter++;
          setTimeout( () => {
            const cString = this.state.currentString.substr(0, this.state.currentString.length - 1);
            this.setState({
              currentString: cString,
            });
          }, 170 * loopConter);
  
        });
      });

    }, 500);
    let loopConter = 0;
  }

  render()
  {
    return (
      <span className="cursor-str">{this.state.currentString}</span>
    );
  }
}

export class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      hidden_desc_1: 'hidden_description_close',
      hidden_desc_2: 'hidden_description_close',
      hidden_desc_3: 'hidden_description_close',
    };
    
    this.setOpenText = this.setOpenText.bind(this);
    this.setCloseText = this.setCloseText.bind(this);
  }
  
  setOpenText(event){
    this.setState({
      [event.target.id]: 'hidden_description',
    });
  }  
  setCloseText(event){
    console.log(event.target.id);
    this.setState({
      [event.target.id]: 'hidden_description_close',
    });
  }

  render()
  {
    return (
      <React.Fragment>
      <div className="root">
      <div className="container-fluid">
          <div className="row">

            <div className="col-xl-7 offset-xl-1 col-lg-12 col-md-12 col-sm-12 col-12 left-column-p">

              <div className="row row-m">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 t-l xs-t-c">
                  <img className="logo" src="/img/small-logo-new.png"/>
                  <h2 className="title">Joint-Studio.ru</h2>
                  
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 t-c xs-t-c">
                  <h3 className="header-phone"><a href="tel: +79999812359"><i class="fas fa-phone-square-alt"></i>  +7 999 981 23 59</a></h3>
                </div>
              </div>

              <div className="row row-m t-l">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h2>Разработаем</h2>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h2><HandlerTextChange/></h2>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h2>с формой захвата <span className="under-line-red">за 3 дня</span></h2>
                  <h2>или сделаем бесплатно</h2>
                </div>
              </div>

              <div className="row row-m t-l">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <p>Протестируйте нишу с помощью нашего инструмента формата “oneScreen” за счет сильного заголовка и контекстной рекламы и сэкономьте время и деньги на разработку полноценного сайта.</p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <p>Разрабатываем продуманные и современные e-commerce для тех, кто рассчитывает на действительно серьезный поток клиентов, и тех, кто устал от вечных заплаток на старом сайте.</p>
                </div>
              </div>

              <div className="row row-m hidden-low-width">

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                  <div className="row">

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                      <p className="iconText"><i class="fas fa-chart-line"></i></p>
                      <p className="secondText">Подключение аналитики</p>
                      <div className="hidden_popup_button">
                        <p onClick={this.setOpenText} id="hidden_desc_1">Подробнее <i class="fas fa-angle-right"></i></p>
                      </div>
                      <div className={this.state.hidden_desc_1}>
                        <p>Аналитика собирает все данные по любым рекламным источникам в единую базу и отображает бизнес-показатели по ним в наглядном виде.</p>
                        <div className="close_button"><button onClick={this.setCloseText} id="hidden_desc_1">Закрыть <i class="fas fa-times"></i></button></div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                      <p className="iconText"><i class="fas fa-funnel-dollar"></i></p>
                      <p className="secondText">Конверсия от 7%</p>
                      <div className="hidden_popup_button">
                        <p onClick={this.setOpenText} id="hidden_desc_2">Подробнее <i class="fas fa-angle-right"></i></p>
                      </div>
                      <div className={this.state.hidden_desc_2}>
                        <p>Конверсия из лидов в клиенты (Lead Conversion Rate) — это процентное количество заявок, которые стали реальными клиентами, совершили покупку.</p>
                        <div className="close_button"><button onClick={this.setCloseText} id="hidden_desc_2">Закрыть <i class="fas fa-times"></i></button></div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                      <p className="iconText"><i class="far fa-clock"></i></p>
                      <p className="secondText">Загрузка сайта за 0,28 сек</p>
                      <div className="hidden_popup_button">
                        <p onClick={this.setOpenText} id="hidden_desc_3">Подробнее <i class="fas fa-angle-right"></i></p>
                      </div>
                      <div className={this.state.hidden_desc_3}>
                        <p>Скорость загрузки сайта один из наиболее важных показателей. Если сайт долго загружается, пользователи будут уходить.</p>
                        <div className="close_button"><button onClick={this.setCloseText} id="hidden_desc_3">Закрыть <i class="fas fa-times"></i></button></div>
                      </div>
                    </div>

                </div>

              </div>

            </div>

          </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-12 right-column-p">
              <FormComponent />
              <div className="row r-c-description">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <p>Есть вопрос?</p>
                  <p>Ответим в мессенджерах</p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="row mt-3 mb-4">
                    <div className="col-6 sociallinks">
                      <a className="telegram" href="https://teleg.run/sidirovich"><i class="fab fa-telegram-plane"></i></a>
                    </div>
                    <div className="col-6 sociallinks">
                      <a className="whatsapp" href="https://wa.me/79999812359"><i class="fab fa-whatsapp"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>

        </div>

        </div>

      <div class="backgound-l-column"></div>
      </React.Fragment>
    );
  }
}

export default App;